import { Injectable } from '@angular/core';

import { RestService } from './rest.service';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  constructor(private readonly rest: RestService) {}

  getProviders(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/provider`);
  }

  getProviderProfiles(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/provider/profile`);
  }

  getProviderProfile(id: number): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/provider/profile/${id}`);
  }

  createProviderProfile(payload): Promise<any> {
    return this.rest.post(`${environment.apiUrl}/api/provider/profile`, payload);
  }

  deleteProviderProfile(id: number): Promise<any> {
    return this.rest.delete(`${environment.apiUrl}/api/provider/profile/${id}`);
  }

  saveProviderProfile(id: number, payload): Promise<any> {
    return this.rest.put(`${environment.apiUrl}/api/provider/profile/${id}`, payload);
  }
}
