import { Injectable } from '@angular/core';

import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';

import { UiKitAlertComponent } from '../common/ui-kit/alert/alert';

export type OpenAlertSimpleOpts = string;

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private readonly snackBar: MatSnackBar) {}

  open(message: OpenAlertSimpleOpts): MatSnackBarRef<UiKitAlertComponent> {
    const config = new MatSnackBarConfig();
    config.duration = 5000;

    const snackBarRef = this.snackBar.openFromComponent(UiKitAlertComponent, {
      horizontalPosition: 'start',
      data: {
        message,
      },
    });

    return snackBarRef;
  }
}
